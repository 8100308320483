import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      // {
      //   path: "/order",
      //   name: "order",
      //   component: () => import("../views/mall/Order.vue")
      // },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/changeRecord",
        name: "changeRecord",
        component: () => import("../views/mall/ChangeRecord.vue")
      },
      {
        path: "/scoreRecord",
        name: "scoreRecord",
        component: () => import("../views/mall/ScoreRecord.vue")
      },
      {
        path: "/djscoreRecord",
        name: "djscoreRecord",
        component: () => import("../views/mall/DJScoreRecord.vue")
      },
      {
        path: "/articleCate",
        name: "articleCate",
        component: () => import("../views/mall/ArticleCate.vue")
      },
      {
        path: "/articleList",
        name: "articleList",
        component: () => import("../views/mall/ArticleList.vue")
      },
      {
        path: "/scoreGoods",
        name: "scoreGoods",
        component: () => import("../views/mall/ScoreGoods.vue")
      },
      {
        path: "/scoreOrder",
        name: "scoreOrder",
        component: () => import("../views/mall/ScoreOrder.vue")
      },
      {
        path: "/shopExpress",
        name: "shopExpress",
        component: () => import("../views/mall/ShopExpress.vue")
      },
      {
        path: "/scoreGoodsCate",
        name: "scoreGoodsCate",
        component: () => import("../views/mall/ScoreGoodsCate.vue")
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("../views/this/Coupon.vue")
      },
      {
        path: "/couponRecord",
        name: "couponRecord",
        component: () => import("../views/this/CouponRecord.vue")
      },
      {
        path: "/goodscate",
        name: "goodscate",
        component: () => import("../views/this/GoodsCate.vue")
      },
      {
        path: "/goods",
        name: "goods",
        component: () => import("../views/this/Goods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/this/Order.vue")
      },
      {
        path: "/wborder",
        name: "wborder",
        component: () => import("../views/this/WBOrder.vue")
      },
      {
        path: "/masterRecord",
        name: "masterRecord",
        component: () => import("../views/this/MasterRecord.vue")
      },
      {
        path: "/orderRecord",
        name: "orderRecord",
        component: () => import("../views/this/OrderRecord.vue")
      },
      {
        path: "/cityconfig",
        name: "cityconfig",
        component: () => import("../views/this/CityConfig.vue")
      },
      {
        path: "/projectList",
        name: "projectList",
        component: () => import("../views/other/ProjectList.vue")
      },
      {
        path: "/newsCate",
        name: "newsCate",
        component: () => import("../views/other/NewsCate.vue")
      },
      {
        path: "/newsList",
        name: "newsList",
        component: () => import("../views/other/NewsList.vue")
      },
      {
        path: "/vip_Sign",
        name: "vip_Sign",
        component: () => import("../views/other/Vip_Sign.vue")
      },
      {
        path: "/applyVip",
        name: "applyVip",
        component: () => import("../views/other/ApplyVip.vue")
      },
      {
        path: "/storeList",
        name: "storeList",
        component: () => import("../views/other/StoreList.vue")
      },
      {
        path: "/masterList",
        name: "masterList",
        component: () => import("../views/other/MasterList.vue")
      },
      {
        path: "/worker",
        name: "worker",
        component: () => import("../views/user/Worker.vue")
      },
      {
        path: "/store",
        name: "store",
        component: () => import("../views/user/Store.vue")
      },
      {
        path: "/vip",
        name: "vip",
        component: () => import("../views/user/Vip.vue")
      },
      {
        path: "/masterProject",
        name: "masterProject",
        component: () => import("../views/other/MasterProject.vue")
      },
      {
        path: "/master_Collect",
        name: "master_Collect",
        component: () => import("../views/other/Master_Collect.vue")
      },
      {
        path: "/draw",
        name: "draw",
        component: () => import("../views/other/Draw.vue")
      },
      {
        path: "/applyBill",
        name: "applyBill",
        component: () => import("../views/other/ApplyBill.vue")
      },
      {
        path: "/shopList",
        name: "shopList",
        component: () => import("../views/other/ShopList.vue")
      },
      {
        path: "/vipStore",
        name: "vipStore",
        component: () => import("../views/other/VipStore.vue")
      },
    ]
  },
  {
    path: "/img",
    name: "img",
    component: () => import("../views/ImageList.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
